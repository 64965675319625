import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import config from 'devextreme/core/config';
import { AppModule } from './app/app.module';
import { licenseKey } from './devextreme-license';
import { environment } from './environments/environment';
config({ licenseKey });

if (environment.production) {
	enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
	.catch(err => console.error(err));
