import { AfterViewInit, Component, Inject, Injector } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import DevExpress from 'devextreme';
import config from 'devextreme/core/config';
import { locale } from "devextreme/localization";
import dxButton from 'devextreme/ui/button';
import dxDataGrid from "devextreme/ui/data_grid";
import dxForm from 'devextreme/ui/form';
import dxPopup from 'devextreme/ui/popup';

import 'reflect-metadata';
import { catchError } from 'rxjs';
import { Environment } from '../environments/environment-interface';
import { ENVIRONMENT } from './app.module';
import { AppInfoService } from './services/app-info.service';
import { UCLookupService } from './services/uc-lookup.service';

export let AppInjector: Injector;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	host: { hostID: crypto.randomUUID().toString() }
})
export class AppComponent implements AfterViewInit {
	public authenticated = false;
	public drawerOpened = true;
	public toggleDrawerButtonOptions: DevExpress.ui.dxButton.Properties = {
		icon: 'menu',
		onClick: () => {
			this.drawerOpened = !this.drawerOpened;
		},
		stylingMode: 'text'
	}

	public toggleThemeButtonOptions: DevExpress.ui.dxButton.Properties = {
		icon: 'palette',
		onClick: () => {
			this.appInfoService.theme = this.appInfoService.theme == 'dark' ? 'light' : 'dark';

		},
		stylingMode: 'text'
	}

	public userMenuOptions: DevExpress.ui.dxDropDownButton.Properties = {
		text: "User",
		items: [
			{
				text: "Log out",
				onClick: () => this.authService.logout({
					logoutParams: {
						returnTo: `${window.location.protocol}//${window.location.host}`
					}
				})
			}
		],
		stylingMode: 'text',
	}

	constructor(
		@Inject(ENVIRONMENT) protected environment: Environment,
		public appInfoService: AppInfoService,
		private authService: AuthService,
		private injector: Injector,
		private ucLookupService: UCLookupService
	) {
		locale(navigator.language);

		AppInjector = this.injector;
		this.appInfoService.initTheme();

		this.authService.user$.subscribe(user => {
			this.userMenuOptions.text = user?.name;
			this.userMenuOptions.icon = user?.picture;
		});

		function lockHeight(e?: HTMLElement) {
			if (!e)
				return;

			const flex = getComputedStyle(e).flex.substring(0, 1) == '1';
			if (flex && !e.style.maxHeight) {
				const h = e.offsetHeight;
				e.style.maxHeight = `${h}px`;
				//console.log('Lock height', h, e);
			}
		};

		dxDataGrid.defaultOptions<DevExpress.ui.dxDataGrid.Properties>({
			device: { deviceType: "desktop" },
			options: {
				paging: { enabled: false },
				columnAutoWidth: true,
				onContentReady: (e) => lockHeight(e.element),
				onInitialized: (e) => lockHeight(e.element)
			}
		});

		dxButton.defaultOptions<DevExpress.ui.dxButton.Properties>({
			options: {
				stylingMode: 'contained',
				type: 'default'
			}
		});

		dxForm.defaultOptions<DevExpress.ui.dxForm.Properties>({
			options: {
				labelMode: 'floating',
			}
		});

		dxPopup.defaultOptions<DevExpress.ui.dxPopup.Properties>({
			options: {
				shadingColor: 'rgba(0,0,0,0.4)'
			}
		});

		config({
			floatingActionButtonConfig: {
				maxSpeedDialActionCount: 100,
			},
		});
	}

	public ngAfterViewInit(): void {
		const params = window.location.search
			.substring(1)
			.split("&")
			.reduce((acc: { [k: string]: string }, curr) => {
				const [k, v] = curr.split('=');
				acc[k] = v;
				return acc;
			}, {})

		this.authService.isAuthenticated$.subscribe(authenticated => {
			console.log('AUTHENTICATED', authenticated);
			this.authenticated = authenticated;

			if (authenticated) {
				this.authService.getAccessTokenSilently(
					{
						authorizationParams: {
							audience: this.environment.auth0.audience,
						}
					}

				)
					.pipe(
						catchError((err, ca) => {
							console.log('ERROR. Get access token silently failed:', err);
							this.authService.logout({
								logoutParams: {
									returnTo: `${window.location.protocol}//${window.location.host}`
								}
							});
							return ca;
						}),
					)
					.subscribe(async token => {
						this.appInfoService.setToken(token);
					});
			} else {

				const hostname = window.location.hostname;

				this.ucLookupService.read(hostname).subscribe(uc => {
					console.log('UC', uc);

					this.authService.loginWithRedirect({
						authorizationParams: {
							logo: uc.logo,
							banner: uc.banner,
							connection: uc.connection,
							login_hint: params['username']
						}
					})
				})
			}
		});
	}
}
