import { Injectable } from '@angular/core';
import { PermissionType } from 'tw2-common';

@Injectable({
	providedIn: 'root'
})
export class DocumentationService {
	private texts: { [key in PermissionType]: { caption: string, text: string } } = {
		lorawan_device_profile: {
			caption: 'LoRaWAN device profile',
			text: 'LoRaWAN device profile'
		},
		lorawan_device: {
			caption: 'LoRaWAN device',
			text: 'LoRaWAN device'
		},
		lorawan_device_radio_link: {
			caption: 'LoRaWAN device radio link',
			text: 'LoRaWAN device radio link'
		},
		lorawan_gateway: {
			caption: 'LoRaWAN gateway',
			text: 'Represents a LoRaWAN gateway'
		},
		lorawan_gateway_credentials: {
			caption: 'LoRaWAN gateway credentials',
			text: 'Credentials for a LoRaWAN gateway'
		},
		lorawan_data: {
			caption: 'LoRaWAN data',
			text: 'text about data'
		},
		organization_user: {
			caption: 'Organization user',
			text: 'text about organization_user'
		},
		location: {
			caption: 'Location',
			text: 'text about location'
		},
		organization: {
			caption: 'Organization',
			text: 'text about organization'
		},
		partner_user: {
			caption: 'Partner user',
			text: 'text about partner_user'
		},
		partner: {
			caption: 'Partner',
			text: 'Partner is the top level node in the hierarchy, but a partner can be a child of another partner.'
		},
		sitestack_camera: {
			caption: 'Camera',
			text: 'text about camera'
		},
		sitestack_speaker: {
			caption: 'Speaker',
			text: 'text about speaker'
		},
		sitestack_speaker_audio: {
			caption: 'Speaker audio',
			text: 'text about speaker audio'
		},
		sitestack_station: {
			caption: 'Station',
			text: 'text about station'
		},
		sitestack_station_status: {
			caption: 'Station status',
			text: 'text about station status'
		},
		sitestack_camera_detected_object: {
			caption: 'Detected objects',
			text: 'Detected objects'
		},
		sitestack_camera_event: {
			caption: 'Camera event',
			text: 'Camera event'
		},
		sitestack_camera_recording: {
			caption: 'Camera recording',
			text: 'Camera recording'
		},
		sitestack_camera_snapshot: {
			caption: 'Camera snapshot',
			text: 'Camera snapshot'
		},
		sitestack_camera_status: {
			caption: 'Camera status',
			text: 'Camera status'
		},
		sitestack_network_device_status: {
			caption: 'Network device status',
			text: 'Network device status'
		},
		sitestack_network_device: {
			caption: 'Network device',
			text: 'Network device'
		},
		sitestack_nox_control_unit_status: {
			caption: 'NOX control unit status',
			text: 'NOX control unit status'
		},
		sitestack_nox_control_unit: {
			caption: 'NOX control unit',
			text: 'NOX control unit'
		},
		sitestack_speaker_status: {
			caption: 'Speaker status',
			text: 'Speaker status'
		},
		webhook_data: {
			caption: 'Webhook data',
			text: 'Webhook data'
		},
		webhook_device: {
			caption: 'Webhook device',
			text: 'Webhook device'
		},
		webhook_url: {
			caption: 'Webhook URL',
			text: 'Webhook data URL'
		},
		ble_beacon: {
			caption: 'BLE beacon',
			text: 'BLE beacon'
		},
		organization_user_invitation: {
			caption: 'Organization user invitation',
			text: 'Organization user invitation'
		},
		partner_user_invitation: {
			caption: 'Partner user invitation',
			text: 'Partner user invitation'
		},
		sitewatch_extension: {
			caption: 'Sitewatch',
			text: 'Sitewatch'
		},
		analyzero_extension: {
			caption: 'Analyzero',
			text: 'Analyzero'
		},
		carie_extension: {
			caption: 'Carie',
			text: 'Carie'
		},
		neowit_extension: {
			caption: 'Neowit',
			text: 'Neowit'
		},
		lorawan_sensor: {
			caption: 'LoRaWAN sensor',
			text: 'LoRaWAN sensor'
		},
		webhook_sensor: {
			caption: 'Webhook sensor',
			text: 'Webhook sensor'
		},
		sitestack_station_input_status: {
			caption: 'Station input status',
			text: 'Station input status'
		},
		sitestack_station_metrics: {
			caption: 'Sitestack station metrics',
			text: 'Sitestack station metrics'
		},
		sitestack_station_monitor_event: {
			caption: 'Sitestack station monitor_event',
			text: 'Sitestack station monitor_event'
		},
		sitestack_station_monitor: {
			caption: 'Sitestack station monitor',
			text: 'Sitestack station monitor'
		},
		sitestack_station_plugin: {
			caption: 'Sitestack station plugin',
			text: 'Sitestack station plugin'
		},
		lorawan_device_status: {
			caption: 'LoRaWAN device status',
			text: 'LoRaWAN device status'
		},
		lorawan_gateway_status: {
			caption: 'LoRaWAN gateway status',
			text: 'LoRaWAN gateway status'
		}
	}

	public getCaption(type: PermissionType) {
		return this.texts[type].caption;
	}

	public getText(type: PermissionType) {
		return this.texts[type].text;
	}
}
