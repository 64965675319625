import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { Params, Router } from '@angular/router';
import DevExpress from 'devextreme';
import { DxButtonModule, DxPopoverModule, DxPopupModule, DxTreeViewComponent, DxTreeViewModule } from 'devextreme-angular';
import { DxTemplateModule } from 'devextreme-angular/core';
import { PositionConfig } from 'devextreme/animation/position';
import { Observable, firstValueFrom } from 'rxjs';
import { AppInfoService } from 'src/app/services/app-info.service';
import { ModelType, OrganizationModel, PartnerAttribute, PartnerModel, PermissionType, getPath, getPermissionType } from 'tw2-common';
import { ModelMQTTService } from '../../services/model.mqtt.service';
import { OrganizationEditComponent } from "../organization/organization-edit.component";
import { PartnerEditComponent } from '../partner/partner-edit.component';

type ModelItem = DevExpress.ui.dxTreeView.Item & { type: PermissionType, text: string };

@Component({
	selector: 'tw2-tree',
	templateUrl: './tree.component.html',
	styleUrls: ['./tree.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [DxTreeViewModule, DxTemplateModule, DxButtonModule, DxPopoverModule, DxPopupModule, CommonModule, PartnerEditComponent, OrganizationEditComponent]
})
export class TreeComponent {
	private organizations: Observable<OrganizationModel[]>;
	private partners: Observable<PartnerModel[]>;

	@ViewChild(DxTreeViewComponent, { static: false }) public tree!: DxTreeViewComponent;

	public addOrganizationPopupVisible = false;
	public addPartnerPopupVisible = false;
	public dataSource: ModelItem[] = [];
	public newOrganization?: OrganizationModel;
	public newPartner?: PartnerModel;
	public subMenuModelItem!: ModelItem;
	public subMenuPosition: PositionConfig = {};
	public subMenuVisible: boolean = false;

	constructor(
		private modelService: ModelMQTTService,
		private router: Router,
		private cdr: ChangeDetectorRef,
		public appInfoService: AppInfoService
	) {
		this.partners = this.modelService.list(PartnerModel);
		this.organizations = this.modelService.list(OrganizationModel);

		this.partners.subscribe(partners => this.updateDataSource(PartnerModel, partners));
		this.organizations.subscribe(organizations => this.updateDataSource(OrganizationModel, organizations));
	}

	public action(path: (string | number)[], params?: Params) {
		this.router.navigate(path, { queryParams: params });
	}

	public addModelPopupShown(event: DevExpress.ui.dxPopup.ShownEvent) {
		event.component.repaint();
	}

	public addOrganization() {
		this.newOrganization = Object.assign(new OrganizationModel(), { partnerId: this.subMenuModelItem.id });
		this.addOrganizationPopupVisible = true;
	}

	public addPartner() {
		this.newPartner = Object.assign(new PartnerModel(), { parentId: this.subMenuModelItem.id });
		this.addPartnerPopupVisible = true;
	}

	public onItemClick(event: DevExpress.ui.dxTreeView.ItemClickEvent) {
		const modelItem = event.itemData as ModelItem;

		setTimeout(async () => {
			this.tree.instance.selectItem(modelItem);
			const modelPath = await this.getModelPath(modelItem);
			if (modelPath.join('/') == window.location.pathname.substring(1))
				return;
			this.router.navigateByUrl(modelPath.join('/'))
		})
	}

	public openSubMenu(e: DevExpress.ui.dxButton.ClickEvent, item: ModelItem) {
		this.subMenuModelItem = item;
		this.subMenuVisible = true;
		this.subMenuPosition = {
			my: 'left top',
			at: 'left bottom',
			of: e.element
		}
	}

	private async getModelPath(item: ModelItem): Promise<string[]> {
		switch (item.type) {
			case 'organization':
				const organizations = await firstValueFrom(this.organizations);
				return getPath(OrganizationModel, organizations.find(o => o.id == item.id) as OrganizationModel);

			case 'partner':
				const partners = await firstValueFrom(this.partners);
				return getPath(PartnerModel, partners.find(o => o.id == item.id) as PartnerModel);
		}
		return [];
	}

	// private selectByURL(url: string) {
	// 	const id = url.split('?')[0].split('/').pop();
	// 	const mi = this.dataSource.find(mi => mi.id == id) || this.dataSource[0];

	// 	setTimeout(() => {
	// 		this.selectItem(mi);
	// 		this.cdr.detectChanges();
	// 	})
	// }
	private modelToModelItem<T extends PartnerAttribute | PartnerModel>(
		modelType: ModelType<T>,
		model: T
	): ModelItem {
		return {
			expanded: true,
			id: model.id,
			text: model.label,
			parentId: model instanceof PartnerModel ? model.parentId : model.partnerId,
			template: model instanceof PartnerModel ? 'partner-item' : 'organization-item',
			type: getPermissionType(modelType),
		}
	}

	private updateDataSource<T extends PartnerAttribute | PartnerModel>(
		modelType: ModelType<T>,
		models: T[]
	) {
		if (modelType == PartnerModel) {
			const partners = models as PartnerModel[]
			const root = partners.find(m => m.parentId == undefined || !partners.some(p => p.id == m.parentId));
			if (root)
				root.parentId = undefined;
		}

		const removed = this.dataSource.filter(mi => !models.some(m =>
			mi.type !== getPermissionType(modelType) ||
			mi.id == m.id
		));
		const selectedNode = this.tree.instance.getSelectedNodes()[0];
		removed.forEach(o => {
			if (selectedNode?.itemData?.id == o.id)
				this.tree.instance.selectItem(o.parentId);
			const i = this.dataSource.findIndex(mi => mi.id == o.id);
			this.dataSource.splice(i, 1);
		})

		models.forEach(o => {
			const i = this.dataSource.findIndex(mi => mi.id == o.id);
			if (i == -1)
				this.dataSource.push(this.modelToModelItem(modelType, o))
			else
				this.dataSource.splice(i, 1, this.modelToModelItem(modelType, o));
		});
		this.dataSource = this.dataSource.sort((a, b) => {
			if (a.type < b.type)
				return 1;
			if (a.type > b.type)
				return -1;
			if (a.type == b.type) {
				if (a.text?.toLocaleLowerCase() < b.text?.toLocaleLowerCase())
					return -1;
				if (a.text?.toLocaleLowerCase() > b.text?.toLocaleLowerCase())
					return 1;
			}
			return 0;

		})
		//this.selectByURL(this.router.url);
		this.cdr.detectChanges();
	}
}
