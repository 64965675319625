import { Component } from '@angular/core';
import DevExpress from 'devextreme';
import DXFileUploader from 'devextreme/ui/file_uploader';
import ISO6391 from 'iso-639-1';
import { Localization, PartnerModel, Paths } from 'tw2-common';
import { Action, ModelEditComponent, ModelEditComponentImports } from '../model-edit/model-edit.component';

@Component({selector: 'tw2-partner-edit',
	templateUrl: '../model-edit/model-edit.component.html',
	styleUrls: ['../model-edit/model-edit.component.scss', './partner-edit.component.scss'],
	standalone: true,
	imports: ModelEditComponentImports,
	host: { hostID: crypto.randomUUID().toString() }})
export class PartnerEditComponent extends ModelEditComponent<PartnerModel> {
	protected modelType = PartnerModel;

	public actions: Action[] = [];
	public deleteAction = {
		title: 'Delete partner',
		message: 'The partner and all its data will be removed.'
	};

	public override async ngOnInit() {
		super.ngOnInit();
		this.route.queryParams.subscribe(params => {
			if ('parentId' in params)
				this.model!.parentId = params['parentId']
		});
	}

	protected async getItems(): Promise<(DevExpress.ui.dxForm.Item & { dataField?: Paths<PartnerModel>; })[]> {
		const iFields: ['logo' | 'banner', string, string, string][] = [
			['logo', 'Logo', '.png', 'image/png'],
			['banner', 'Banner', '.jpg', 'image/jpeg'],
		]

		const imageFields = iFields.map(([dataField, name, extension, mimeType]) => {
			return {
				itemType: 'group',
				caption: name,
				items: [
					{
						dataField: dataField,
						label: { visible: false },
						template: (data: any, itemElement: HTMLElement) => {
							if (this.model && this.model[dataField]) {
								const img = document.createElement('img');
								img.classList.add('logo');
								const image = this.model[dataField] as string;

								if (image.endsWith(extension)) {
									// Logo is path
									img.setAttribute('src', `${this.environment.assetServerURL}/${image}?token=${this.appInfoService.getToken()}`);
								} else {
									// Logo is base64 encoded
									img.setAttribute('src', `data:${mimeType};base64, ${image}`);
								}
								itemElement.appendChild(img);
								return;
							}

							itemElement.appendChild(document.createTextNode(`${name} not set.`));
						}
					},
					{
						itemType: 'simple',
						template: (data: any, itemElement: HTMLElement) => {
							const div = document.createElement('div');
							itemElement.appendChild(div);
							new DXFileUploader(div, {
								selectButtonText: "Upload image",
								labelText: "",
								accept: mimeType,
								uploadMode: "useForm",
								showFileList: false,
								onValueChanged: (e) => {
									if (e.value && e.value[0]) {
										const file = e.value[0];
										const fr = new FileReader();
										fr.onload = () => {
											const str = btoa(fr.result as string);
											this.model![dataField] = str;
											this.form.instance.repaint()
										};
										fr.readAsBinaryString(file);
									} else {
										console.log("File not selected or browser incompatible.")
									}
								}
							});
						}
					}
				]
			} as (DevExpress.ui.dxForm.Item & { dataField?: Paths<PartnerModel>; })
		});

		return [
			{
				itemType: 'group',
				caption: 'Settings',
				items: [
					{
						dataField: 'name',
						isRequired: true
					},
					{
						dataField: 'language',
						isRequired: true,
						editorType: 'dxSelectBox',
						editorOptions: {
							valueExpr: 'id',
							displayExpr: 'name',
							items: Object.values(Localization.languageValues).map(l => ({
								id: l,
								name: ISO6391.getName(l)
							})).sort((a, b) => a.name.localeCompare(b.name)),
						} as DevExpress.ui.dxSelectBox.Properties
					},
					{
						dataField: 'domainName',
						helpText: this.model?.isNew ? "This cannot be changed later." : "",
						isRequired: true,
						editorOptions: {
							readOnly: !this.model?.isNew,
						} as DevExpress.ui.dxTextBox.Properties
					},


				] as (DevExpress.ui.dxForm.Item & { dataField: Paths<PartnerModel>; })[]
			},
			{
				itemType: 'group',
				caption: 'Contact information',
				items: [
					{
						itemType: 'group',
						colCount: 2,
						items: [
							{
								dataField: 'addressStreetAddress',
								label: { text: 'Street address' },
								colSpan: 2,
							},
							{
								label: { text: 'Postal code' },
								dataField: 'addressPostalCode',
							},
							{
								label: { text: 'City' },
								dataField: 'addressCity',
							},
						] as (DevExpress.ui.dxForm.Item & { dataField: Paths<PartnerModel>; })[]
					},
					{
						dataField: 'phoneNumber',
					},
					{
						dataField: 'website',
					},
					{
						dataField: 'email',
						helpText: 'Outgoing email address for user invitations. This must be verified before use.',
					},
				] as (DevExpress.ui.dxForm.Item & { dataField: Paths<PartnerModel>; })[]
			},
			{
				itemType: 'group',
				caption: 'Extensions',
				items: [
					{
						label: { text: 'Enable Analyzero' },
						dataField: 'extensionAnalyzeroEnabled',
						editorType: 'dxCheckBox'
					},
					{
						label: { text: 'Enable Carie' },
						dataField: 'extensionCarieEnabled',
						editorType: 'dxCheckBox'
					},
					{
						label: { text: 'Enable Neowit' },
						dataField: 'extensionNeowitEnabled',
						editorType: 'dxCheckBox',
					},
					{
						label: { text: 'Enable Sitewatch' },
						dataField: 'extensionSitewatchEnabled',
						editorType: 'dxCheckBox'
					},

				] as (DevExpress.ui.dxForm.Item & { dataField: Paths<PartnerModel>; })[]
			},
			...imageFields

		]
	}

	protected async ready(): Promise<void> {

		if (this.model?.isNew) {
			const partner = await this.httpRead(PartnerModel, { id: this.model.parentId });
			this.model.language = partner.language;
		}

	}
}
