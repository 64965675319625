import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { EMPTY, Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Filter, ListOptions, Model, ModelType, getPath } from 'tw2-common';
import { plainToClass } from 'tw2-transform-validate';
import { Environment } from '../../environments/environment-interface';
import { ENVIRONMENT } from '../app.module';
import { ModelService } from './model.service';

type Path<T extends Model> = { [key in keyof T]?: any[] | any } | Partial<T>;

@Injectable({
	providedIn: 'root'
})
export class ModelHTTPService implements ModelService {
	private errorHandler = new Subject<string>();

	constructor(
		@Inject(ENVIRONMENT) protected environment: Environment,
		protected http: HttpClient
	) { }


	public count<T extends Model>(
		modelType: ModelType<T>,
		keys: Path<T> = {},
		filter: Filter<T>[]
	): Observable<number> {
		const path = getPath(modelType, keys as T, '*').slice(0, -1);

		let params = new HttpParams();
		params = params.set('filter', JSON.stringify(filter));

		return this.http.head(`${this.environment.tw2APIURL}/${path.join('/')}`, { observe: 'response', params })
			.pipe(
				catchError((err) => this.handleError(err, false)),
				map(response => {
					const count = response.headers.get('X-Count');
					if (!count)
						return 0;
					return parseInt(count)
				}),
			);
	}

	public create<T extends Model>(modelType: ModelType<T>, data: T, expectError = false): Observable<T> {
		const path = this.getPath(modelType, data, false, true);
		return this.http.post<T>(`${this.environment.tw2APIURL}/${path}`, data)
			.pipe(
				catchError((err) => this.handleError(err, expectError)),
				map(m => plainToClass(modelType, m))
			);
	}

	public delete<T extends Model>(modelType: ModelType<T>, data: T, expectError = false): Observable<T> {
		const path = this.getPath(modelType, data, false);
		return this.http.delete<T>(`${this.environment.tw2APIURL}/${path}`)
			.pipe(
				catchError((err) => this.handleError(err, expectError)),
				map(m => plainToClass(modelType, m))
			);
	}


	public list<T extends Model>(
		modelType: ModelType<T>,
		keys: Path<T> = {},
		options: ListOptions<T> = new ListOptions<T>(),
		expectError = false
	): Observable<T[]> {
		const path = this.getPath(modelType, keys as T, true, true);

		let params = new HttpParams();
		Object.entries(options).forEach(([k, v]) => {
			if (v !== undefined)
				params = params.set(k, JSON.stringify(v));
		});

		return this.http.get<T[]>(`${this.environment.tw2APIURL}/${path}`, { params })
			.pipe(
				catchError((err) => this.handleError(err, expectError)),
				map(m => m.map(m => plainToClass(modelType, m)).filter(m => this.filter(m, keys))),
			);
	}

	public read<T extends Model>(
		modelType: ModelType<T>,
		keys: Path<T> = {},
		expectError = false
	): Observable<T> {
		const path = this.getPath(modelType, keys as T, true);
		return this.http.get(`${this.environment.tw2APIURL}/${path}`)
			.pipe(
				catchError((err) => this.handleError(err, expectError)),
				map(m => plainToClass(modelType, m))
			);
	}

	public update<T extends Model>(modelType: ModelType<T>, data: T, expectError = false): Observable<T> {
		const path = this.getPath(modelType, data, false);
		return this.http.put<T>(`${this.environment.tw2APIURL}/${path}`, data)
			.pipe(
				catchError((err) => this.handleError(err, expectError)),
				map(m => plainToClass(modelType, m))
			);
	}

	private filter<T extends Model>(model: T, filter: Path<T>): boolean {
		const ok = Object
			.entries(filter)
			.every(([k, v]) => {
				if (v == undefined)
					return true
				if (!(k in model))
					return true;
				if (v instanceof Array)
					return v.some(v => model[k as keyof T] == v);
				return model[k as keyof T] == v;
			})
		return ok;
	}

	// private getFilter<T extends Model>(modelType: ModelType<T>, keys: Path<T> = {}) {
	// 	if (keys instanceof Model)
	// 		return getModelFromAncestor(modelType, keys);
	// 	return keys;
	// }

	// private getPath<T extends Model>(modelType: ModelType<T>, data: T, removeLastElement = false) {
	// 	const path = getPath(modelType, data)
	// 	if (removeLastElement)
	// 		path.pop();
	// 	if (path.includes('+'))
	// 		throw "Invalid characters in path";

	// 	return path.join('/');
	// }


	private getPath<T extends Model>(modelType: ModelType<T>, data: T, allowWildCards: boolean, removeLastElement = false) {
		//Object.assign(data, this.baseFilterValue);
		const path = getPath(modelType, data)
		if (removeLastElement)
			path.pop();

		if (path.includes('+')) {
			if (allowWildCards) {
				path.forEach((p, i) => {
					if (p == '+')
						path[i] = '*'
				})
			} else {
				console.log("Invalid characters in path", modelType, path);
				throw "Invalid characters in path";
			}
		}
		return path.join('/');
	}

	private handleError(httpError: HttpErrorResponse, expectError: boolean) {
		if (expectError)
			return EMPTY;
		if (httpError.error instanceof ErrorEvent) {
			// A client-side or network error occurred. Handle it accordingly.
			console.error('An error occurred:', httpError.error.message);
			return throwError(httpError.error.message);
		} else {
			// The backend returned an unsuccessful response code.
			// The response body may contain clues as to what went wrong.
			console.error('Error', httpError)
			console.error(
				`Backend returned code ${httpError.status}, ` +
				`body was: ${httpError.error}`);
			return throwError(httpError.error);
		}
	}

}
