import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import {
	Auth0UserContext,
	Model,
	ModelType,
	PartnerUserModel,
	PermissionType,
	UserContext,
	getPermissionType
} from 'tw2-common';
import { Breadcrumb } from '../components/breadcrumbs/breadcrumbs.component';

export type Theme = 'dark' | 'light';

@Injectable({
	providedIn: 'root'
})
export class AppInfoService {
	private breadcrumbList: Breadcrumb[] = [];
	private renderer: Renderer2;
	private token: string = '';

	public breadcrumbs: Subject<Breadcrumb[]> = new Subject();
	public loadPanel = {
		visible: false,
		message: ''
	}

	constructor(
		private rendererFactory: RendererFactory2,
		private router: Router,
	) {
		this.renderer = this.rendererFactory.createRenderer(null, null);
	}

	public get theme(): Theme {
		let theme = localStorage.getItem('theme');
		if (theme)
			return theme as Theme;
		return 'light';
	}

	public set theme(theme: Theme) {
		localStorage.setItem('theme', theme);
		this.initTheme();
	}

	public get userContext(): UserContext<PartnerUserModel> {
		if (!this.token)
			return new Auth0UserContext({} as any);

		const [, payload] = this.token.split('.');
		const str = atob(payload);
		return new Auth0UserContext(JSON.parse(str));
	}

	public addBreadcrumb(breadcrumb: Breadcrumb) {
		if (this.breadcrumbList.indexOf(breadcrumb) !== -1)
			return;

		setTimeout(() => {
			this.breadcrumbList.push(breadcrumb)
			this.breadcrumbs.next(this.breadcrumbList);
		})
	}

	public getDescription(type: ModelType<Model>) {
		const p = getPermissionType(type);
		return models[p][2];
	}

	public getIcon(type: ModelType<Model>) {
		const p = getPermissionType(type);
		return models[p][3];
	}

	public getTitle(type: ModelType<Model>, plural = false) {
		const p = getPermissionType(type);
		return models[p][plural ? 0 : 1];
	}

	public getToken() {
		return this.token;
	}

	public goHome() {
		this.router.navigate(['/']);
	}

	public hideLoadPanel() {
		setTimeout(() => {
			this.loadPanel.visible = false;
		})
	}

	public initTheme() {
		this.renderer.removeClass(document.body, 'dx-swatch-tw2-light');
		this.renderer.removeClass(document.body, 'dx-swatch-tw2-dark');
		this.renderer.addClass(document.body, this.theme == 'light' ? 'dx-swatch-tw2-light' : 'dx-swatch-tw2-dark');
	}

	public removeBreadcrumb(breadcrumb: Breadcrumb) {
		const i = this.breadcrumbList.indexOf(breadcrumb);
		if (i == -1)
			return;
		this.breadcrumbList.splice(i, 1);
		this.breadcrumbs.next(this.breadcrumbList);
	}

	public setToken(token: string) {
		this.token = token;
	}

	public showLoadPanel(message: string) {
		setTimeout(() => {
			this.loadPanel.message = message;
			this.loadPanel.visible = true;
		})
	}
}

const models: { [key in PermissionType]: [string, string, string, string] } = {
	analyzero_extension: [
		"Analyzero",
		"Analyzero",
		"Manage Analyzero",
		"fa-solid fa-link"
	],
	carie_extension: [
		"Carie",
		"Carie",
		"Manage Carie",
		"fa-solid fa-link"
	],
	lorawan_data: [
		"Data",
		"Data",
		"Manage Data",
		"fa-solid fa-asterisk"
	],
	lorawan_device: [
		"LoRaWAN devices",
		"LoRaWAN device",
		"Manage LoRaWAN devices",
		"fa-solid fa-walkie-talkie"
	],
	lorawan_device_profile: [
		"LoRaWAN device profiles",
		"LoRaWAN device profile",
		"Manage LoRaWAN device profiles",
		"fa-solid fa-sliders"
	],
	lorawan_device_radio_link: [
		"LoRaWAN device radio links",
		"LoRaWAN device radio link",
		"Manage LoRaWAN device radio links",
		"fa-solid fa-tower-cell"
	],
	lorawan_device_status: [
		"LoRaWAN device status",
		"LoRaWAN device status",
		"LoRaWAN device status",
		"fa-solid fa-walkie-talkie"
	],
	lorawan_gateway: [
		"Gateways",
		"Gateway",
		"Manage Gateways",
		"fa-solid fa-tower-broadcast"
	],
	lorawan_gateway_credentials: [
		"Gateway credentials",
		"Gateway credentials",
		"Manage Gateway credentials",
		"fa-solid fa-key"
	],
	lorawan_gateway_status: [
		"LoRaWAN gateway status",
		"LoRaWAN gateway status",
		"LoRaWAN gateway status",
		"fa-solid fa-tower-broadcast"
	],
	lorawan_sensor: [
		"LoRaWAN sensor",
		"LoRaWAN sensor",
		"Manage LoRaWAN sensor",
		"fa-solid fa-link"
	],
	location: [
		"Locations",
		"Location",
		"Manage locations",
		"fa-solid fa-location-dot"
	],
	neowit_extension: [
		"Neowit",
		"Neowit",
		"Manage Neowit",
		"fa-solid fa-link"
	],
	organization: [
		"Organizations",
		"Organization",
		"Edit organization",
		"fa-solid fa-building"
	],
	organization_user: [
		"Users",
		"User",
		"Manage Users",
		"fa-solid fa-user"
	],
	organization_user_invitation: [
		"Organization user invitations",
		"Organization user invitation",
		"Manage Organization user invitations",
		"fa-solid fa-link"
	],
	partner: [
		"Partners",
		"Partner",
		"Edit partner",
		"fa-solid fa-briefcase"
	],
	partner_user: [
		"Partner users",
		"Partner user",
		"Manage Users",
		"fa-solid fa-user"
	],
	partner_user_invitation: [
		"Partner user invitations",
		"Partner user invitation",
		"Manage Partner user invitations",
		"fa-solid fa-link"
	],
	sitestack_camera: [
		"Cameras",
		"Camera",
		"Manage Cameras",
		"fa-solid fa-video"
	],
	sitestack_camera_detected_object: [
		"Detected objects",
		"Detected object",
		"Manage Detected objects",
		"fa-solid fa-video"
	],
	sitestack_camera_event: [
		"Events",
		"Event",
		"Manage events",
		"fa-solid fa-camera"
	],
	sitestack_camera_recording: [
		"Camera recordings",
		"Camera recording",
		"Manage Camera recordings",
		"fa-solid fa-video"
	],
	sitestack_camera_snapshot: [
		"Camera snapshots",
		"Camera snapshot",
		"Manage Camera snapshots",
		"fa-solid fa-image"
	],
	sitestack_camera_status: [
		"Camera status",
		"Camera status",
		"Camera status",
		"fa-solid fa-video"
	],
	sitestack_modbus_server: [
		"Modbus servers",
		"Modbus server",
		"Manage Modbus server",
		"fa-solid fa-microchip"
	],
	sitestack_modbus_server_coil: [
		"Coils",
		"Coil",
		"Manage coil",
		"fa-solid fa-power-off"
	],
	sitestack_modbus_server_coil_status: [
		"Coil status",
		"Coil status",
		"Manage coil status",
		"fa-solid fa-puzzle-piece"
	],
	sitestack_modbus_server_discrete_input: [
		"Discrete inputs",
		"Discrete input",
		"Manage discrete input",
		"fa-solid fa-chart-gantt"
	],
	sitestack_modbus_server_discrete_input_status: [
		"Discrete input status",
		"Discrete input status",
		"Manage discrete input status",
		"fa-solid fa-puzzle-piece"
	],
	sitestack_modbus_server_holding_register: [
		"Holding registers",
		"Holding register",
		"Manage holding register",
		"fa-solid fa-sliders"
	],
	sitestack_modbus_server_holding_register_status: [
		"Holding register status",
		"Holding register status",
		"Manage holding register status",
		"fa-solid fa-puzzle-piece"
	],
	sitestack_modbus_server_input_register: [
		"Input registers",
		"Input register",
		"Manage input register",
		"fa-solid fa-chart-line"
	],
	sitestack_modbus_server_input_register_status: [
		"Input register status",
		"Input register status",
		"Manage input register status",
		"fa-solid fa-puzzle-piece"
	],
	sitestack_modbus_server_status: [
		"Modbus server status",
		"Modbus server status",
		"Modbus server status",
		"fa-solid fa-server"
	],
	sitestack_network_device: [
		"Network devices",
		"Network device",
		"Manage network devices",
		"fa-solid fa-network-wired"
	],
	sitestack_network_device_status: [
		"Network device status",
		"Network device status",
		"Network device status",
		"fa-solid fa-network-wired"
	],
	sitestack_speaker: [
		"Speakers",
		"Speaker",
		"Manage Speakers",
		"fa-solid fa-volume-high"
	],
	sitestack_speaker_audio: [
		"Speaker audio",
		"Speaker audio",
		"Manage Speaker audio",
		"fa-solid fa-file-audio"
	],
	sitestack_speaker_status: [
		"Speaker status",
		"Speaker status",
		"Speaker status",
		"fa-solid fa-video"
	],
	sitestack_station: [
		"SiteStack Cloud Connectors",
		"SiteStack Cloud Connector",
		"Manage Cloud Connectors",
		"fa-solid fa-server"
	],
	sitestack_station_input_status: [
		"Cloud Connector input status",
		"Cloud Connector input status",
		"Cloud Connector input status",
		"fa-solid fa-wave-square"
	],
	sitestack_station_metrics: [
		"Cloud Connector metrics",
		"Cloud Connector metrics",
		"Manage station metrics",
		"fa-solid fa-chart-line"
	],
	sitestack_station_monitor: [
		"Cloud Connector monitors",
		"Cloud Connector monitor",
		"Manage station monitor",
		"fa-solid fa-circle-nodes"
	],
	sitestack_station_monitor_event: [
		"Cloud Connector monitor event",
		"Cloud Connector monitor event",
		"Manage station monitor event",
		"fa-solid fa-calendar-check"
	],
	sitestack_station_plugin: [
		"Cloud Connector plugins",
		"Cloud Connector plugin",
		"Manage station plugin",
		"fa-solid fa-puzzle-piece"
	],
	sitestack_station_status: [
		"Cloud Connector status",
		"Cloud Connector status",
		"Manage Cloud Connector status",
		"fa-solid fa-wave-square"
	],
	sitewatch_extension: [
		"Sitewatch",
		"Sitewatch",
		"Manage Sitewatch",
		"fa-solid fa-link"
	],
	webhook_data: [
		"Data",
		"Data",
		"Manage Data",
		"fa-solid fa-asterisk"
	],
	webhook_device: [
		"Webhook devices",
		"Webhook device",
		"Manage Webhook devices",
		"fa-solid fa-cloud-arrow-down"
	],
	webhook_sensor: [
		"Webhook sensor",
		"Webhook sensor",
		"Manage Webhook sensor",
		"fa-solid fa-link"
	],
	webhook_url: [
		"Webhook URLs",
		"Webhook URL",
		"Manage Webhook URLs",
		"fa-solid fa-link"
	]
}
