import { Directive, EventEmitter, OnInit, Output, ViewContainerRef } from '@angular/core';

@Directive({
	selector: '[appDynamicContent]',
	standalone: true
})
export class DynamicContentDirective implements OnInit {
	@Output() public onReady: EventEmitter<ViewContainerRef> = new EventEmitter();

	constructor(public viewContainerRef: ViewContainerRef) { }

	public ngOnInit(): void {
		this.onReady.emit(this.viewContainerRef);
	}
}
