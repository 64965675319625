import { CommonModule } from '@angular/common';
import { Directive, EventEmitter, Input, Output, Type, ViewChild, ViewContainerRef } from '@angular/core';
import DevExpress from 'devextreme';
import { DxButtonModule, DxDropDownBoxModule, DxFormComponent, DxFormModule, DxLoadPanelModule, DxMultiViewModule, DxTagBoxModule, DxTemplateModule } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import { ReplaySubject, Subject, combineLatest, debounceTime } from 'rxjs';
import { DynamicContentDirective } from 'src/app/directives/dynamic-content/dynamic-content.directive';
import { Model, Paths, getPath } from 'tw2-common';
import { validateSync } from 'tw2-transform-validate';
import { ModelComponent } from '../model/model.component';

export type Action = DeleteAction & {
	visibility: 'new' | 'existing' | 'both',
	type: DevExpress.common.ButtonType;
	button: {
		icon: string;
		text: string;
		onClick: () => void;
		disabled?: boolean
	}
}

export type DeleteAction = {
	title: string;
	message: string;
}

@Directive()
export abstract class ModelEditComponent<T extends Model> extends ModelComponent<T> {
	private formReady = new ReplaySubject<DxFormComponent>(1);
	private formRefreshed = new Subject<void>();

	protected dynamicContentViewContainerRef: { [id: string]: ViewContainerRef } = {};

	@Input()
	public useLoadingPanel = true;
	@Output()
	public onSave = new EventEmitter<T>();

	public abstract actions: Action[];
	public abstract deleteAction?: DeleteAction;
	public deleteActionStep = 0;
	public form!: DxFormComponent;
	public itemsWrapper: DevExpress.ui.dxForm.Item[] = [];
	public saveButtonOptions: DevExpress.ui.dxButton.Properties = {
		text: 'Save',
		type: 'default',
		disabled: false,
		onClick: async () => {
			if (!this.model || !this.isValid())
				return;

			if (this.model.id == undefined)
				this.model = await this.httpCreate(this.modelType, this.model);
			else
				this.model = await this.httpUpdate(this.modelType, this.model);

			if (this.onSave.observed)
				this.onSave.emit(this.model);
			else
				notify(
					{ message: "Model saved", },
					'success',
					500
				);
			//this.router.navigateByUrl(getPath(this.modelType, this.model).join('/'));
		}
	}

	@ViewChild(DxFormComponent) public set _form(form: DxFormComponent) {
		if (this.form == form || !form)
			return;
		this.form = form;
		this.formReady.next(form);
	}

	public async delete() {
		await this.httpDelete(this.modelType, this.model!)
		this.close();
	}

	public isValid(): boolean {
		const formValid = this.form.instance.validate().isValid as boolean;
		const errors = validateSync(this.model!);

		if (errors.length)
			console.log('Validation failed for', this.model);
		errors.forEach(e => {
			console.log(e);
		})
		return errors.length == 0 && formValid;
	}

	public override async ngOnInit() {
		super.ngOnInit();
		this.lifeCycleSubscriptions.push(
			this.modelReady.subscribe(model => {
				this.refreshForm();
			}),

			combineLatest([this.formReady, this.formRefreshed])
				.pipe(debounceTime(100))
				.subscribe(() => {
					// Hack to prevent ExpressionChangedAfterItHasBeenCheckedError
					this.ready();
				})
		)
	}

	public onDynanicContentReady(ref: ViewContainerRef, item: DevExpress.ui.dxForm.SimpleItem) {
		const itemName = item.dataField || item.name;
		if (!itemName) {
			throw new Error('item.dataField or item.name must be defined.')
		}
		this.dynamicContentViewContainerRef[itemName] = ref;
	}

	public setDynamicContent<C>(id: string, componentType: Type<C>) {
		if (!this.dynamicContentViewContainerRef[id]) {
			const err = `DynamicContentViewContainerRef not found for ${id}`;
			console.error(err);
			throw new Error(err)
		}
		this.dynamicContentViewContainerRef[id].clear();
		const componentRef = this.dynamicContentViewContainerRef[id].createComponent(componentType);
		return componentRef;
	}

	protected isNew() {
		return this.model?.id == undefined;
	}

	protected makeReadOnly() {
		this.form.instance.option('readOnly', true);
		this.saveButtonOptions.disabled = true;
	}

	protected async refreshForm() {
		this.itemsWrapper = await this.getItems();
		this.formRefreshed.next();
	}

	protected abstract getItems(): Promise<(DevExpress.ui.dxForm.Item & { dataField?: Paths<T> })[]>;
	protected abstract ready(): Promise<void>;

	private close() {
		const p = getPath(this.modelType, this.model);
		p.pop();
		this.router.navigateByUrl(p.join('/'));
	}
}

export const ModelEditComponentImports = [
	CommonModule,
	DxButtonModule,
	DxDropDownBoxModule,
	DxFormModule,
	DxLoadPanelModule,
	DxMultiViewModule,
	DxTagBoxModule,
	DxTemplateModule,
	DynamicContentDirective
];
