import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import {
	Auth0UserContext,
	Model,
	ModelType,
	PartnerUserModel,
	PermissionType,
	UserContext,
	getPermissionType
} from 'tw2-common';
import { Breadcrumb } from '../components/breadcrumbs/breadcrumbs.component';

export type Theme = 'dark' | 'light';

@Injectable({
	providedIn: 'root'
})
export class AppInfoService {
	private breadcrumbList: Breadcrumb[] = [];
	private renderer: Renderer2;
	private token: string = '';

	public breadcrumbs: Subject<Breadcrumb[]> = new Subject();
	public loadPanel = {
		visible: false,
		message: ''
	}

	constructor(
		private rendererFactory: RendererFactory2,
		private router: Router,
	) {
		this.renderer = this.rendererFactory.createRenderer(null, null);
	}

	public get theme(): Theme {
		let theme = localStorage.getItem('theme');
		if (theme)
			return theme as Theme;
		return 'light';
	}

	public set theme(theme: Theme) {
		localStorage.setItem('theme', theme);
		this.initTheme();
	}

	public get userContext(): UserContext<PartnerUserModel> {
		if (!this.token)
			return new Auth0UserContext({} as any);

		const [, payload] = this.token.split('.');
		const str = atob(payload);
		return new Auth0UserContext(JSON.parse(str));
	}

	public addBreadcrumb(breadcrumb: Breadcrumb) {
		if (this.breadcrumbList.indexOf(breadcrumb) !== -1)
			return;

		setTimeout(() => {
			this.breadcrumbList.push(breadcrumb)
			this.breadcrumbs.next(this.breadcrumbList);
		})
	}

	public getDescription(type: ModelType<Model>) {
		const p = getPermissionType(type);
		return models[p][2];
	}

	public getIcon(type: ModelType<Model>) {
		const p = getPermissionType(type);
		return models[p][3];
	}

	public getTitle(type: ModelType<Model>, plural = false) {
		const p = getPermissionType(type);
		return models[p][plural ? 0 : 1];
	}

	public getToken() {
		return this.token;
	}

	public goHome() {
		this.router.navigate(['/']);
	}

	public hideLoadPanel() {
		setTimeout(() => {
			this.loadPanel.visible = false;
		})
	}

	public initTheme() {
		this.renderer.removeClass(document.body, 'dx-swatch-tw2-light');
		this.renderer.removeClass(document.body, 'dx-swatch-tw2-dark');
		this.renderer.addClass(document.body, this.theme == 'light' ? 'dx-swatch-tw2-light' : 'dx-swatch-tw2-dark');
	}

	public removeBreadcrumb(breadcrumb: Breadcrumb) {
		const i = this.breadcrumbList.indexOf(breadcrumb);
		if (i == -1)
			return;
		this.breadcrumbList.splice(i, 1);
		this.breadcrumbs.next(this.breadcrumbList);
	}

	public setToken(token: string) {
		this.token = token;
	}

	public showLoadPanel(message: string) {
		setTimeout(() => {
			this.loadPanel.message = message;
			this.loadPanel.visible = true;
		})
	}
}

const models: { [key in PermissionType]: [string, string, string, string] } = {
	ble_beacon: [
		'BLE beacons',
		'BLE beacon',
		'BLE beacon',
		'fa-solid fa-link'
	],
	lorawan_data: [
		'Data',
		'Data',
		'Manage Data',
		'fa-solid fa-asterisk'
	],
	lorawan_device_profile: [
		'LoRaWAN device profiles',
		'LoRaWAN device profile',
		'Manage LoRaWAN device profiles',
		'fa-solid fa-sliders'
	],
	lorawan_device_radio_link: [
		'LoRaWAN device radio links',
		'LoRaWAN device radio link',
		'Manage LoRaWAN device radio links',
		'fa-solid fa-tower-cell'
	],
	lorawan_device: [
		'LoRaWAN devices',
		'LoRaWAN device',
		'Manage LoRaWAN devices',
		'fa-solid fa-walkie-talkie'
	],
	lorawan_gateway_credentials: [
		'Gateway credentials',
		'Gateway credentials',
		'Manage Gateway credentials',
		'fa-solid fa-key'
	],
	lorawan_gateway: [
		'Gateways',
		'Gateway',
		'Manage Gateways',
		'fa-solid fa-tower-broadcast'
	],
	location: [
		'Locations',
		'Location',
		'Manage locations',
		'fa-solid fa-location-dot'
	],
	organization_user: [
		'Users',
		'User',
		'Manage Users',
		'fa-solid fa-user'
	],
	organization: [
		'Organizations',
		'Organization',
		'Edit organization',
		'fa-solid fa-building'
	],
	partner_user: [
		'Partner users',
		'Partner user',
		'Manage Users',
		'fa-solid fa-user'
	],
	partner: [
		'Partners',
		'Partner',
		'Edit partner',
		'fa-solid fa-briefcase'
	],
	sitestack_station_input_status: [
		'Station input status',
		'Station input status',
		'Station input status',
		'fa-solid fa-wave-square'
	],
	sitestack_camera_event: [
		'Events',
		'Event',
		'Manage events',
		'fa-solid fa-camera'
	],
	sitestack_camera_snapshot: [
		'Camera snapshots',
		'Camera snapshot',
		'Manage Camera snapshots',
		'fa-solid fa-image'
	],
	sitestack_camera_status: [
		'Camera status',
		'Camera status',
		'Camera status',
		'fa-solid fa-video'
	],
	sitestack_camera_recording: [
		'Camera recordings',
		'Camera recording',
		'Manage Camera recordings',
		'fa-solid fa-video'
	],
	sitestack_camera: [
		'Cameras',
		'Camera',
		'Manage Cameras',
		'fa-solid fa-video'
	],
	sitestack_camera_detected_object: [
		'Detected objects',
		'Detected object',
		'Manage Detected objects',
		'fa-solid fa-video'
	],
	sitestack_network_device_status: [
		'Network device status',
		'Network device status',
		'Network device status',
		'fa-solid fa-network-wired'
	],
	sitestack_network_device: [
		'Network devices',
		'Network device',
		'Manage network devices',
		'fa-solid fa-network-wired'
	],
	sitestack_nox_control_unit_status: [
		'NOX control unit status',
		'NOX control unit status',
		'NOX control unit status',
		'fa-solid fa-server'
	],
	sitestack_nox_control_unit: [
		'NOX control units',
		'NOX control unit',
		'Manage NOX control unit',
		'fa-solid fa-server'
	],
	sitestack_speaker_audio: [
		'Speaker audio',
		'Speaker audio',
		'Manage Speaker audio',
		'fa-solid fa-file-audio'
	],
	sitestack_speaker_status: [
		'Speaker status',
		'Speaker status',
		'Speaker status',
		'fa-solid fa-video'
	],
	sitestack_speaker: [
		'Speakers',
		'Speaker',
		'Manage Speakers',
		'fa-solid fa-volume-high'
	],
	sitestack_station_status: [
		'Station status',
		'Station status',
		'Manage Station status',
		'fa-solid fa-wave-square'
	],
	sitestack_station: [
		'SiteStack Stations',
		'SiteStack Station',
		'Manage Stations',
		'fa-solid fa-server'
	],
	tw1_lorawan_data: [
		'Data',
		'Data',
		'Manage Data',
		'fa-solid fa-asterisk'
	],
	tw1_lorawan_device: [
		'TW1 LoRaWAN devices',
		'TW1 LoRaWAN device',
		'Manage TW1 LoRaWAN devices',
		'fa-solid fa-walkie-talkie'
	],
	tw1_lorawan_service: [
		'TW1 LoRaWAN services',
		'TW1 LoRaWAN service',
		'Manage TW1 LoRaWAN services',
		'fa-solid fa-1'
	],
	webhook_data: [
		'Data',
		'Data',
		'Manage Data',
		'fa-solid fa-asterisk'
	],
	webhook_device: [
		'Webhook devices',
		'Webhook device',
		'Manage Webhook devices',
		'fa-solid fa-cloud-arrow-down'
	],
	webhook_url: [
		'Webhook URLs',
		'Webhook URL',
		'Manage Webhook URLs',
		'fa-solid fa-link'
	],
	organization_user_invitation: [
		'Organization user invitations',
		'Organization user invitation',
		'Manage Organization user invitations',
		'fa-solid fa-link'
	],
	partner_user_invitation: [
		'Partner user invitations',
		'Partner user invitation',
		'Manage Partner user invitations',
		'fa-solid fa-link'
	],
	sitewatch_extension: [
		'Sitewatch',
		'Sitewatch',
		'Manage Sitewatch',
		'fa-solid fa-link'
	],
	analyzero_extension: [
		'Analyzero',
		'Analyzero',
		'Manage Analyzero',
		'fa-solid fa-link'
	],
	carie_extension: [
		'Carie',
		'Carie',
		'Manage Carie',
		'fa-solid fa-link'
	],
	neowit_extension: [
		'Neowit',
		'Neowit',
		'Manage Neowit',
		'fa-solid fa-link'
	],
	lorawan_sensor: [
		'LoRaWAN sensor',
		'LoRaWAN sensor',
		'Manage LoRaWAN sensor',
		'fa-solid fa-link'
	],
	webhook_sensor: [
		'Webhook sensor',
		'Webhook sensor',
		'Manage Webhook sensor',
		'fa-solid fa-link'
	],
	sitestack_station_metrics: [
		'Station metrics',
		'Station metrics',
		'Manage station metrics',
		'fa-solid fa-chart-line'
	],
	sitestack_station_monitor_event: [
		'Station monitor event',
		'Station monitor event',
		'Manage station monitor event',
		'fa-solid fa-calendar-check'
	],
	sitestack_station_monitor: [
		'Station monitor',
		'Station monitor',
		'Manage station monitor',
		'fa-solid fa-circle-nodes'
	],
	lorawan_device_status: [
		'LoRaWAN device status',
		'LoRaWAN device status',
		'LoRaWAN device status',
		'fa-solid fa-walkie-talkie'
	],
	lorawan_gateway_status: [
		'LoRaWAN gateway status',
		'LoRaWAN gateway status',
		'LoRaWAN gateway status',
		'fa-solid fa-tower-broadcast'
	]
}
