import { Environment } from './environment-interface';

export const environment: Environment = {
	production: false,

	assetServerURL: 'https://assets.thingsweb.wtf',
	auth0: {
		clientId: 'vHCTMBa0i28qR9XLJDpp6wlelmcPRUIv',
		domain: 'qa-tw2.eu.auth0.com',
		audience: 'https://api.thingsweb.wtf'
	},
	googleAPIsKey: 'AIzaSyDYWuWP5y9m3l8lcVHeT2Gk4TceoBpi9NY',
	inventoryAPIURL: 'https://inventory.thingsweb.wtf',
	lorawanCUPSURL: 'https://cups.thingsweb.wtf:443',
	sitestackHTTPProxyDomain: 'sitestack-proxy.thingsweb.wtf',
	sitestackStationCommandProxyDomain: 'sitestack-command-proxy.thingsweb.wtf',
	sitestackVideoProxyDomain: 'sitestack-video-proxy.thingsweb.wtf',
	ucLookupURL: "https://uc-lookup.thingsweb.wtf/tw2",
	webhookServerURL: 'https://webhook.thingsweb.wtf',
	tw2APIURL: 'https://api.thingsweb.wtf',
	tw2MQTTBroker: {
		hostname: 'api-mqtt.thingsweb.wtf',
		port: 443,
		protocol: 'wss'
	},
	neowitIntegrationURL: 'http://api.thingsweb.wtf:3012'
};