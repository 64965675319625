import { Component } from '@angular/core';
import DevExpress from 'devextreme';
import { getHierarchy, Hierarchy, PartnerModel } from 'tw2-common';
import { DocumentationService } from '../../services/documentation.service';
import { DxScrollViewModule, DxTreeViewModule } from 'devextreme-angular';

type DocumenationHierarchy = Hierarchy & {
	caption: string,
	text: string,
	children: DocumenationHierarchy[],
	expanded: boolean
};

@Component({selector: 'tw2-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    standalone: true,
    imports: [DxScrollViewModule, DxTreeViewModule],
	host: { hostID: crypto.randomUUID().toString() }})
export class HomeComponent {

	public selectedItem!: DocumenationHierarchy;
	public hierarchy: DocumenationHierarchy[];

	public onItemSelectionChanged(event: DevExpress.ui.dxTreeView.SelectionChangedEvent) {
		console.log('EVENT', event);
		//this.selectedItem = event.itemData as DocumenationHierarchy;
	}

	constructor(service: DocumentationService) {

		function getDocumentationHierarchy(h: Hierarchy): DocumenationHierarchy {
			return {
				...h,
				children: h.children.map(c => getDocumentationHierarchy(c)),
				caption: service.getCaption(h.permissionType),
				text: service.getText(h.permissionType),
				expanded: true
			}
		}

		this.hierarchy = [getDocumentationHierarchy(getHierarchy(PartnerModel))];
	}

	public onTreeInitialized(event: DevExpress.ui.dxTreeView.InitializedEvent) {
		setTimeout(() => {
			event.component?.expandItem(this.hierarchy[0]);
			event.component?.selectItem(this.hierarchy[0]);
		});
	}

}
