import { ChangeDetectorRef, Directive, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, Scroll } from '@angular/router';
import { ReplaySubject, Subscription, debounceTime, filter, firstValueFrom } from 'rxjs';
import { Filter, ListOptions, Model, ModelType, getModelFromPath, getPath } from 'tw2-common';
import { Environment } from '../../../environments/environment-interface';
import { ENVIRONMENT } from '../../app.module';
import { AppInfoService } from '../../services/app-info.service';
import { ModelHTTPService } from '../../services/model.http.service';
import { ModelMQTTService } from '../../services/model.mqtt.service';

@Directive()
export abstract class ModelComponent<T extends Model> implements OnInit, OnDestroy {
	private _model?: T;

	protected modelReady = new ReplaySubject<T>(1);
	protected abstract modelType: ModelType<T>;
	protected subscriptions: Subscription[] = [];

	public loading: boolean = true;

	constructor(
		@Inject(ENVIRONMENT) protected environment: Environment,
		protected route: ActivatedRoute,
		protected router: Router,
		protected cdr: ChangeDetectorRef,
		protected appInfoService: AppInfoService,
		private modelServiceHTTP: ModelHTTPService,
		protected modelServiceMQTT: ModelMQTTService
	) { }

	@Input()
	public set model(value: T | undefined) {
		if (JSON.stringify(this._model) == JSON.stringify(value))
			return;

		this._model = value;
		if (value)
			this.modelReady.next(value);
	}

	public get model(): T | undefined {
		return this._model;
	}

	protected get path(): string[] {
		return window.location.pathname
			.split('/')
			.filter(p => p)
			.map(p => decodeURIComponent(p))
	}

	public ngOnDestroy() {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	public async ngOnInit() {
		if (this.model) {
			this.loading = false;
		} else {
			this.subscriptions.push(
				this.router.events
					.pipe(filter(event => event instanceof NavigationStart))
					.subscribe(async (event) => {
						this.loading = true;
					}),
				this.router.events
					.pipe(filter(event => event instanceof NavigationEnd || event instanceof Scroll))
					.pipe(debounceTime(50))
					.subscribe(async (event) => {
						await this.loadModel();
						this.loading = false;
					})
			);
		}
	}

	protected httpCount<T extends Model>(
		modelType: ModelType<T>,
		keys: { [key in keyof T]?: string[] | string } | Partial<T> = {},
		filter: Filter<T>[] = []
	): Promise<number> {
		return firstValueFrom(this.modelServiceHTTP.count(modelType, keys, filter));
	}

	protected httpCreate<T extends Model>(modelType: ModelType<T>, data: T): Promise<T> {
		return firstValueFrom(this.modelServiceHTTP.create(modelType, data));
	}

	protected httpDelete<T extends Model>(modelType: ModelType<T>, data: T): Promise<T> {
		return firstValueFrom(this.modelServiceHTTP.delete(modelType, data));
	}

	protected httpList<T extends Model>(
		modelType: ModelType<T>,
		keys: { [key in keyof T]?: string[] | string } | Partial<T> = {},
		options: ListOptions<T> = new ListOptions<T>(),
		expectError = false
	): Promise<T[]> {
		return firstValueFrom(this.modelServiceHTTP.list(modelType, keys, options, expectError));
	}

	protected httpRead<T extends Model>(modelType: ModelType<T>, keys: { [key in keyof T]?: string[] | string } | Partial<T> = {}, expectError = false): Promise<T> {
		return firstValueFrom(this.modelServiceHTTP.read(modelType, keys, expectError));
	}

	protected httpUpdate<T extends Model>(modelType: ModelType<T>, data: T): Promise<T> {
		return firstValueFrom(this.modelServiceHTTP.update(modelType, data));
	}

	protected mqttList<T extends Model>(
		modelType: ModelType<T>,
		cb: (model: T[]) => void,
		keys: { [key in keyof T]?: string[] | string } | Partial<T> = {},
		debounceTimeout = 50
	) {
		this.subscriptions.push(this.modelServiceMQTT.list(modelType, keys, debounceTimeout).subscribe(models => cb(models)));
	}

	protected mqttLatest<T extends Model>(
		modelType: ModelType<T>,
		cb: (model: T) => void,
		keys: { [key in keyof T]?: string[] | string } | Partial<T> = {}
	) {
		this.subscriptions.push(this.modelServiceMQTT.latest(modelType, keys).subscribe(model => cb(model)));
	}

	protected mqttRead<T extends Model>(
		modelType: ModelType<T>,
		cb: (model: T) => void,
		keys: { [key in keyof T]?: string[] | string } | Partial<T> = {}
	) {
		this.subscriptions.push(this.modelServiceMQTT.read(modelType, keys).subscribe(model => cb(model)));
	}

	private async loadModel() {
		const path = this.path;
		if (this.model) {
			const modelPath = getPath(this.modelType, this.model);
			if (modelPath.every((k, i) => k == path[i])) // No change in path for this component. No need to reload.
				return;
		}

		const m = getModelFromPath<T>(this.modelType, path);
		if (m.id !== undefined) {
			try {
				this.model = await this.httpRead(this.modelType, m);
				return;
			} catch (e) { }
		}
		this.model = Object.assign(new this.modelType(), m);
	}
}
