import { Component } from '@angular/core';
import DevExpress from 'devextreme';
import ISO6391 from 'iso-639-1';
import { Localization, OrganizationModel, PartnerModel, Paths, getModelFromChild } from 'tw2-common';
import { Action, ModelEditComponent, ModelEditComponentImports } from '../model-edit/model-edit.component';

@Component({selector: 'tw2-organization-edit',
	templateUrl: '../model-edit/model-edit.component.html',
	styleUrls: ['../model-edit/model-edit.component.scss'],
	standalone: true,
	imports: ModelEditComponentImports,
	host: { hostID: crypto.randomUUID().toString() }})
export class OrganizationEditComponent extends ModelEditComponent<OrganizationModel> {
	protected modelType = OrganizationModel;

	public actions: Action[] = [];
	public deleteAction = {
		title: 'Delete organization',
		message: 'The organization and all its data will be removed.'
	};

	protected async getItems(): Promise<(DevExpress.ui.dxForm.Item & { dataField?: Paths<OrganizationModel> })[]> {
		return [
			{
				dataField: 'name',
				isRequired: true
			},
			{
				visible: !this.isNew(),
				dataField: 'accountId',
				editorOptions: {
					readOnly: true
				} as DevExpress.ui.dxTextBox.Properties
			},
			{
				dataField: 'language',
				isRequired: true,
				editorType: 'dxSelectBox',
				editorOptions: {
					valueExpr: 'id',
					displayExpr: 'name',
					items: Object.values(Localization.languageValues).map(l => ({
						id: l,
						name: ISO6391.getName(l)
					})).sort((a, b) => a.name.localeCompare(b.name)),
				} as DevExpress.ui.dxSelectBox.Properties
			},
		];
	}

	protected async ready(): Promise<void> {

		if (this.model?.isNew) {
			const partner = await this.httpRead(PartnerModel, getModelFromChild(PartnerModel, this.model));
			this.model.language = partner.language;
		}

	}
}
